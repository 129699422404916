import React from "react";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
} from "react-router-dom";
import './interviewOverzicht.css';
import '../../App.css';

import Interview from "./interview";

// Todo: make dynamic
import media_milandewit_profile from "./content/media_milandewit/profile.jpg";
import media_sanderdewit_profile from './content/media_sanderdewit/profile.jpg';
import media_wilengel_profile from './content/media_wilengel/profile.jpg';


function InterviewOverzicht() {
    let match = useRouteMatch();

    return <Switch>
        <Route path={`${match.path}/:name`}>
            <Interview />
        </Route>
        <Route path={match.path}>
            <div>
                <header className="Overzicht-header">
                    <h2>Interviews</h2>
                </header>
                <div className="Overzicht-back">
                    <div className="Overzicht-body">
                        <div className="row">
                            <div className="column">
                                <Link to={`${match.url}/milandewit`}>
                                    <img className="Overzicht-profile" src={media_milandewit_profile} />
                                </Link>
                            </div>
                            <div className="column">
                                <Link to={`${match.url}/sanderdewit`}>
                                    <img className="Overzicht-profile" src={media_sanderdewit_profile} />
                                </Link>
                            </div>
                            <div className="column">
                                <Link to={`${match.url}/wilengel`}>
                                    <img className="Overzicht-profile" src={media_wilengel_profile} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Route>
    </Switch>;
}

export default InterviewOverzicht;