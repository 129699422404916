import '../../App.css';
import './about.css';

import media_silvandeboer_profile from "./content/profile.jpg";
import media_silvandeboer_cover from "./content/cover.jpg";


function About() {
    // Render the page
    return <div>
        <header
            className="About-header"
            style={{
                backgroundImage: `url(${media_silvandeboer_cover})`,
                backgroundSize: 'max(100%, 1500px)',
                backgroundPosition: 'center'
            }}>
            <h2>Over deze website</h2>
        </header>

        <div className="Interview-back">

            <div className="Interview-body">
                <img className="Interview-profile" src={media_silvandeboer_profile} />
                <p>Deze website is op 19 juli 2021 gelanceerd door mij, Silvan. Ik merkte dat de familie zo groot begon te worden, dat ik moeite kreeg om alleen al iedereens naam te onthouden. Zo kwam ik op het idee om een stamboom te maken. Het leek me ook leuk en interessant om met een aantal familieleden eens een wat dieper gesprek te voeren, en ze zo beter te leren kennen. Door een overvloed aan vrije tijd besloot ik om de stamboom en de gesprekken vast te leggen in een website, zodat de rest van de familie ook de kans heeft om zich te verdiepen in het nageslacht van Jan en Tiny, en iets te leren over een aantal familieleden.</p>

                <p>Ik geloof er sterk in dat je nooit verwachtingen moet scheppen die je niet zeker waar gaat maken. Ik zal deze website minimaal een aantal maanden online houden, en de kans is groot dat ik er nooit meer iets aan ga toevoegen. Mocht iemand een goed idee hebben en zelf iets willen toevoegen, schroom dan niet om contact met me op te nemen, want dat zou ik hartstikke leuk vinden!</p>
            </div>
        </div>
    </div >;
}

export default About;