import React from "react";
import {
    HashRouter,
    Switch,
    Route,
    Link
} from "react-router-dom";
import logo from './logo.svg';
import '../../App.css';

function Home() {
    return <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Welkom op de website van Familie de Wit.</p>
        <ul className="Home-menu">
            <li><Link to="/interview" className="App-link">Klik hier</Link> om een interview te lezen.</li>
            <li><Link to="/stamboom" className="App-link">Klik hier</Link> om de stamboom te bekijken.</li>
            <li><Link to="/about" className="App-link">Klik hier</Link> om te lezen over dit project.</li>
        </ul>
    </header>;
}

export default Home;