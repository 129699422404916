import { Article } from '../pages/interview/content/interviewInfo';

export function getArticle(urlname: string) {
    return new Promise<Article>((resolve) => {
        if (urlname == "milandewit") {
            import('../pages/interview/content/milandewit').then(mod => {
                resolve(mod.data);
            });
        }
        else if (urlname == "sanderdewit") {
            import('../pages/interview/content/sanderdewit').then(mod => {
                resolve(mod.data);
            });
        }
        else if (urlname == "wilengel") {
            import('../pages/interview/content/wilengel').then(mod => {
                resolve(mod.data);
            });
        }
        else {
            import('../pages/interview/content/default').then(mod => {
                resolve(mod.data);
            });
        }
    })
};