import React from "react";
import {
    HashRouter,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { render } from 'react-dom';
import { ReactSVG } from 'react-svg';

import image_stamboom from "./content/stamboom.svg";
import logo from './logo.svg';
import '../../App.css';
import './stamboom.css';

function Stamboom() {
    return <div>
        <header className="App-header" id="Stamboom-header">
            <h2>Stamboom</h2>
        </header>
        <div className="Stamboom-back">
            <div className="Stamboom-body">
                <ReactSVG src={image_stamboom} />
            </div>
        </div>
    </div >
}

export default Stamboom;