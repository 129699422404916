import React, { useEffect, useState } from 'react';
import { getArticle } from '../../services/interview_load';
import { Article, ArticleElement } from './content/interviewInfo';
import { ReactSVG } from 'react-svg';

import '../../App.css';
import './interview.css';
import { useParams } from "react-router-dom";

// Todo: make dynamic
import media_milandewit_profile from "./content/media_milandewit/profile.jpg";
import media_milandewit_cover from "./content/media_milandewit/cover.jpg";
import media_milandewit_stamboom from "./content/media_milandewit/stamboom.svg";
import media_milandewit_samen from "./content/media_milandewit/samen.jpg";
import media_milandewit_livingroom from "./content/media_milandewit/livingroom.jpg";
import media_milandewit_haard from './content/media_milandewit/haard.jpg';
import media_milandewit_shirt from "./content/media_milandewit/shirt.jpg";
import media_milandewit_trommel from "./content/media_milandewit/trommel.jpg";

import media_sanderdewit_profile from './content/media_sanderdewit/profile.jpg';
import media_sanderdewit_cover from './content/media_sanderdewit/cover.jpg';
import media_sanderdewit_stamboom from './content/media_sanderdewit/stamboom.svg';
import media_sanderdewit_samen from './content/media_sanderdewit/samen.jpg';
// import media_sanderdewit_wispo from './content/media_sanderdewit/wispo.jpeg';
import media_sanderdewit_familie from './content/media_sanderdewit/familie.jpeg';
import media_sanderdewit_gezin from './content/media_sanderdewit/gezin.jpeg';
import media_sanderdewit_vroeger from './content/media_sanderdewit/vroeger.jpeg';
import media_sanderdewit_comedancing from './content/media_sanderdewit/comedancing.png'

import media_wilengel_profile from './content/media_wilengel/profile.jpg';
import media_wilengel_cover from './content/media_wilengel/cover.jpg';
import media_wilengel_stamboom from './content/media_wilengel/stamboom.svg';
import media_wilengel_samen from './content/media_wilengel/samen.jpg';
import media_wilengel_collega from './content/media_wilengel/collega.jpeg';
import media_wilengel_gezin from './content/media_wilengel/gezin_anon.jpeg';
import media_wilengel_huis from './content/media_wilengel/huis.jpg';
import media_wilengel_school from './content/media_wilengel/school.jpeg';
import media_wilengel_truck from './content/media_wilengel/truck.jpeg';
// import media_wilengel_motor from './content/media_wilengel/motor.jpeg';


const media: { [id: string]: string; } = {
    media_milandewit_profile: media_milandewit_profile,
    media_milandewit_cover: media_milandewit_cover,
    media_milandewit_stamboom: media_milandewit_stamboom,
    media_milandewit_samen: media_milandewit_samen,
    media_milandewit_livingroom: media_milandewit_livingroom,
    media_milandewit_haard: media_milandewit_haard,
    media_milandewit_shirt: media_milandewit_shirt,
    media_milandewit_trommel: media_milandewit_trommel,

    media_sanderdewit_profile: media_sanderdewit_profile,
    media_sanderdewit_cover: media_sanderdewit_cover,
    media_sanderdewit_stamboom: media_sanderdewit_stamboom,
    media_sanderdewit_samen: media_sanderdewit_samen,
    media_sanderdewit_familie: media_sanderdewit_familie,
    media_sanderdewit_gezin: media_sanderdewit_gezin,
    media_sanderdewit_vroeger: media_sanderdewit_vroeger,
    media_sanderdewit_comedancing: media_sanderdewit_comedancing,

    media_wilengel_profile:media_wilengel_profile,
    media_wilengel_cover:media_wilengel_cover,
    media_wilengel_stamboom:media_wilengel_stamboom,
    media_wilengel_samen:media_wilengel_samen,
    media_wilengel_collega:media_wilengel_collega,
    media_wilengel_gezin:media_wilengel_gezin,
    media_wilengel_huis:media_wilengel_huis,
    media_wilengel_school:media_wilengel_school,
    media_wilengel_truck:media_wilengel_truck
}

type InterviewParams = {
    // Arguments given in the url
    name: string; // full name without spaces used to locate article data
};

function Interview() {
    // Obtain name of interviewee
    const { name } = useParams<InterviewParams>();
    // Define function to load article data, set default data (shown while loading)
    const [article, setArticle] = useState<Article>({
        name: "Laden...",
        urlName: "",
        article: [
            { "type": "text", "content": "Het interview wordt geladen..." }
        ]
    });
    // Start asynchronous loading
    useEffect(() => {
        getArticle(name)
            .then(data =>
                setArticle(data)
            );
    }, []);

    // Render the page
    return <div>
        <header
            className="Interview-header"
            style={{
                backgroundImage: `url(${media["media_" + name + "_cover"]})`,
                backgroundSize: 'max(100%, 1500px)',
                backgroundPosition: 'center'
            }}>
            <h2>{article.name}</h2>
        </header>

        <div className="Interview-back">

            <div className="Interview-body">
                <img className="Interview-profile" src={media["media_" + name + "_profile"]} />

                {article.article.map((element) => {
                    if (element.type == "text") {
                        return <p>{element.content}</p>
                    }
                    else if (element.type == "header") {
                        return <h1 className="Interview-subheader">{element.content}</h1>
                    }
                    else if (element.type == "image") {
                        return <div>
                            {element.filename != null
                                ? <img className="Interview-image" src={media["media_" + name + "_" + element.filename]} />
                                : <p>Afbeelding niet gevonden</p>}
                            {element.content != null
                                ? <p className="Interview-image-description">{element.content}</p>
                                : null}

                        </div>
                    }
                    else if (element.type == "quote") {
                        return <p className="Interview-quote">"{element.content}"</p>
                    }
                    else if (element.type == "stamboom") {
                        if (element.filename != null) {
                            return <div className="Interview-stamboom-body">
                                <ReactSVG src={media["media_" + name + "_" + element.filename]} />
                            </div>
                        } else {
                            return <p>Stamboom niet gevonden</p>
                        }
                    }
                })}

            </div>
        </div>
    </div >;
}

export default Interview;