import React from "react";
import {
    HashRouter,
    Switch,
    Route,
    Link
} from "react-router-dom";
import './App.css';
import Home from "./pages/home/home";
import InterviewOverzicht from "./pages/interview/interviewOverzicht";
import Stamboom from "./pages/stamboom/stamboom";
import About from "./pages/about/about";

function App() {
    return (
        <HashRouter>
            <div className="App">
                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Switch>
                    <Route path="/interview">
                        <InterviewOverzicht />
                    </Route>
                    <Route path="/stamboom">
                        <Stamboom />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>

                <footer>
                    Deze website wordt beheerd door Silvan.
                </footer>
            </div>
        </HashRouter>
    );
}

export default App;